
import { Component, Vue } from "vue-property-decorator"; // import Vue from "vue";
import { goToPage } from "@/scripts/navigation";
import axios from "axios";
// import { MetaInfo } from "vue-meta";
import HelloWorld from "../components/HelloWorld.vue";

@Component({
  // metaInfo() {
  //   const meta: MetaInfo = {
  //     // Children can override the title.
  //     title: "Landing",
  //     meta: [
  //       {
  //         name: "og:image",
  //         content: "https://iscarti.co.za/logo.png",
  //       },
  //       {
  //         httpEquiv: "Content-Type",
  //         content: "text/html; charset=utf-8",
  //       },
  //       { name: "viewport", content: "width=device-width, initial-scale=1" },
  //       {
  //         name: "description",
  //         content: "IsCarTi",
  //       },
  //     ],
  //     link: [
  //       {
  //         rel: "canonical",
  //         href: "https://iscarti.co.za/landing",
  //       },
  //     ],
  //   };
  //   return meta;
  // },
  // name: "HomeView",
  // components: {
  //   HelloWorld,
  // },
})
export default class Landing extends Vue {
  downloadApk() {
    axios({
      url: "/download/iscarti-1-17.apk",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "iscarti-1-17.apk");
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  }
  screenshots = [
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Home - 2 - Calendar.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Home - 3 - Lock.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Home - 1 - Privacy.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Vehicles.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Vehicle Registration.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Attachments.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - History.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Profile.png`),
    },
    {
      src: require(`@/assets/ScreenShots/6.5in-Portrait/Apple 6.5 - Contact.png`),
    },
  ];
  // screenshot = 0;
  banners = [
    {
      src: require(`@/assets/banners/IsCarTi banner 01.png`),
    },
    {
      src: require(`@/assets/banners/IsCarTi banner 02.png`),
    },
    {
      src: require(`@/assets/banners/IsCarTi banner 03.png`),
    },
    {
      src: require(`@/assets/banners/IsCarTi banner 04.png`),
    },
  ];
  // banner = 0;
}
// delay = 3000;
// mounted() {
//   function () {
//   window.setInterval(() => {
//     this.getNotifications()
//   }, 30000)
//  }
// }
