import Vue from "vue";
import Vuetify from "vuetify/lib";
// Theme
// -----
import IsCarTiDarkTheme from "./veutify/dark";
import IsCarTiLightTheme from "./veutify/light";

// Vue 2|3
Vue.use(Vuetify);

// Vue 2
export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    // defaultTheme: "IsCarTiDarkTheme",
    // options: { variations: false },  // Toggle variations
    dark: false,
    themes: {
      light: IsCarTiLightTheme,
      dark: IsCarTiDarkTheme,
    }, // theme: {disable: true}
  },
});
