
import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Terms and Conditions",
  },
})
export default class TandCs extends Vue {
  CompanyName = "ISCARTI TECH (PTY) LTD";
  CompanyRegistration = "2014/168073/07";
  CompanyEMail = "admin@iscarti.co.za";
  ApplicationName = "ISCARTI TECH (PTY) LTD";
  CompanyNumber = "063 685 8113";
  Website = "www.iscarti.co.za";
}
