
import { Component, Vue, Watch } from "vue-property-decorator";
// import AuthModule from "@/store/modules/Auth";
// import NavModule from "@/store/modules/Nav";
// import { StorageLib } from "@/helpers";

@Component
export default class AppBar extends Vue {
  // logoutModal = false;
  value: string | null | undefined = "home";

  // get tab() {
  //   return NavModule.getTab;
  // }
  // set tab(val: "login" | "register") {
  //   NavModule.setTab(val);
  // }

  home(): void {
    // if (this.$route.name != "home") {
    //   this.$router.push({ name: "home" });
    // }
  }

  // @Watch("$route.name", { immediate: true })
  // onRouteChanged(): void {
  //   /* console.log(this.$route.name);
  //   // console.log("Tab:", this.tab); */
  //   this.tab = this.$route.name;
  //   this.value = this.$route.name;
  // }

  // mounted(): void {
  //   this.onRouteChanged();
  // }

  vehicleList(): void {
    // if (this.$route.name != "vehicleList") {
    //   this.$router.push({ name: "vehicleList" });
    // }
  }
  contact(): void {
    // if (this.$route.name != "contactUs") {
    //   this.$router.push({ name: "contactUs" });
    // }
  }

  menu(): void {
    // if (this.$route.name != "profile") {
    //   this.$router.push({ name: "profile" });
    // }
  }

  login(): void {
    // if (this.$route.name != "authentication") {
    //   this.$router.push({ name: "authentication", hash: "#login" });
    // }
  }

  logout(): void {
    // AuthModule.setAuthToken(null);
    // StorageLib.removeLogin();
    // this.logoutModal = false;
  }

  get LoggedIn(): boolean {
    return true; // AuthModule.isLoggedIn;
  }
}
