
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";

@Component({})
export default class Footer extends Vue {
  fab = false;

  onScroll(e: { target: { scrollTop: any } }): void {
    if (typeof window === "undefined") return;
    const top = window.pageYOffset || e.target.scrollTop || 0;
    this.fab = top > 20;
  }

  toTop(): void {
    this.$vuetify.goTo(0);
  }

  get getYear(): string {
    return dayjs().format("YYYY");
  }

  get getVersion() {
    return process.env.VUE_APP_VERSION;
  }
}
