import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Index from "@/views/Index.vue";

// Components
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

// Views
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/T&Cs.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    components: { default: Index, header: Header, footer: Footer },
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    components: {
      default: PrivacyPolicy,
      header: Header,
      footer: Footer,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "termsAndConditions",
    components: {
      default: TermsAndConditions,
      header: Header,
      footer: Footer,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
