import ThemeDefinition from "vuetify";
import colors from "vuetify/lib/util/colors"; // Reference common colors e.g. colors.grey-darken1

export default // IsCarTiDarkTheme: ParsedThemeItem =
{
  primary: "#2278BD", // ParsedThemeItem
  // primary : {
  //   base: "#2278BD",
  //   darken1: "#2278BD",
  // }
  // anchor: colors.blueGrey, // Defaults to `primary`
};
